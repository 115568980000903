<template>
  <div class="">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="caz-blocks-sarcho-title">
              <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">
                Права доступа
              </div>
              <div class="block-sarche">
                <div class="header__search">
                  <crm-input
                    :size="'small'"
                    :class="mode ? 'input__day' : 'input__night'"
                    :className="'w100'"
                    v-model="filterForm"
                    :icon="'el-icon-search'"
                  ></crm-input>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :span="12" class="flex-style text-right">
            <el-dropdown class="setting-cheek ml-2" :class="mode ? 'button__settingsday' : 'button__settingsnight'">
              <el-button
                class="padding_none"
                size="small"
                icon="el-icon-open"
              ></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(column, index) in columns"
                  :key="'drop' + index"
                >
                  <el-checkbox
                    :checked="column.show"
                    @change="check(column.column, $event)"
                    >{{ column.title }}
                  </el-checkbox>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart custom__scroll">
        <table class="table-my-code table-bordered" :class="mode ? 'table__myday' : 'table__mynight'" v-loading="loadingData">
          <thead>
            <tr>
              <th class="w50p" v-if="columns.id.show">
                {{ columns.id.title }}
              </th>
              <th v-if="columns.name.show">
                {{ columns.name.title }}
              </th>
              <th v-if="columns.created_at.show">
                {{ columns.created_at.title }}
              </th>
              <th v-if="columns.updated_at.show">
                {{ columns.updated_at.title }}
              </th>
              <th v-if="columns.settings.show">
                {{ columns.settings.title }}
              </th>
            </tr>

            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.id"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.id.title"
                  class="id_input"
                ></el-input>
              </th>
              <th v-if="columns.name.show">
                <crm-input
                  :placeholder="columns.name.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.name"
                ></crm-input>
              </th>
              <th v-if="columns.created_at.show">
                <crm-date-picker
                  :placeholder="columns.created_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.created_at"
                ></crm-date-picker>
              </th>

              <th v-if="columns.updated_at.show">
                <crm-date-picker
                  :placeholder="columns.updated_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.updated_at"
                ></crm-date-picker>
              </th>

              <th class="settinW" v-if="columns.settings.show"></th>
            </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr
              v-for="(permission, index) in list"
              :key="'permissions-' + index"
              class="cursor-pointer"
            >
              <td v-if="columns.id.show">{{ permission.id }}</td>
              <td v-if="columns.name.show">{{ permission.name }}</td>
              <td v-if="columns.created_at.show">{{ permission.created_at }}</td>
              <td v-if="columns.updated_at.show">{{ permission.updated_at }}</td>
              <td v-if="columns.settings.show" class="settings-td">
                <crm-settings
                  :name="$options.name"
                  :model="permission"
                  :permissionShow="'permissions.show'"
                  :permissionDestroy="'permissions.destroy'"
                  :actions="actions"
                  @edit="edit"
                ></crm-settings>
              </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
        </div>
      </div>
      <div class="app-modal app-modal__full modal-color-bg">
        <el-drawer
          :append-to-body="true"
          :with-header="false"
          :visible.sync="drawerUpdate"
          size="70%"
          ref="drawerUpdate"
          @opened="drawerOpened('drawerUpdateChild')"
          @closed="drawerClosed('drawerUpdateChild')"
        >
          <crm-update
            :selectedItem="selectedItem"
            ref="drawerUpdateChild"
            drawer="drawerUpdate"
          ></crm-update>
        </el-drawer>
      </div>
    </div>
  </div>
</template>

<script>
import CrmUpdate from "./components/crm-update";
import list from "@/utils/mixins/list";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "permissions",
  mixins: [list],
  components: { CrmUpdate },
  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      list: "permissions/list",
      columns: "permissions/columns",
      pagination: "permissions/pagination",
      filter: "permissions/filter",
      sort: "permissions/sort",
      mode: "MODE"
    }),
    actions: function () {
      return ["edit"];
    },
  },
  methods: {
    ...mapActions({
      updateList: "permissions/index",
      setPagination: "permissions/setPagination",
      updateSort: "permissions/updateSort",
      updateFilter: "permissions/updateFilter",
      updateColumn: "permissions/updateColumn",
      updatePagination: "permissions/updatePagination",
      show: "permissions/show",
      empty: "permissions/empty",
      delete: "permissions/destroy",
      refreshData: "permissions/refreshData",
    }),
    check: function (column, event) {
      this.updateColumn({ key: column, value: event });
    },
    
  },
  beforeRouteLeave (to, from, next) {
    this.$store.commit('permissions/EMPTY_LIST');
    next()
  },
};
</script>

